import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';

import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { ServerService } from '../services/server.service';
declare var $:any;
declare var iziToast:any;


import Swal from 'sweetalert2'
@Component({
  selector: 'app-predictive-dialer-contact',
  templateUrl: './predictive-dialer-contact.component.html',
  styleUrls: ['./predictive-dialer-contact.component.css']
})
export class PredictiveDialerContactComponent implements OnInit {
  param1: string;
  created_time;
  modified_time;
  contact_id;
  editContact: FormGroup;
  departments;
  uadmin_id;
  auxcodes;
  fromEdit;
  questions;
  to_num;

  call_record_id;
  admin_id;
  dailyfood = false;
  alladmin = true;
  dsk_access;
  hasdsk = false
  hasnodsk = true;
  external_contact_url;
  public allowMp = true;
  public startrec = false;
  dailyfoodurl;
  has_external_contact;
  IFlink;
  show_caller_id;
  hide_cal1er = '0';
  click_to_call_number;
  click_to_call_mobile_number;
  click_to_call_office_phone;
  click_to_call_home_phone;
  address
  campaign_id
  city
  country
  created_at
  customer_name
  delete_status
  dnd
  lead_id
  notes
  queue_status
  source_data
  state
  phone_number
  updated_at
  zipcode;
  id;
  campaigns
  last_name: any;
  display_name: any;
  street_address: any;
  postal_code: any;
  feedback: any;
  erp_link: any;
  past_notes: any;
  event_history: any;
  duration: any;
  
  constructor(private serverService: ServerService, private router:Router,private route: ActivatedRoute, private sanitizer: DomSanitizer) {
    this.param1 = this.route.snapshot.queryParamMap.get('phone');
  //   var decodedString = atob(this.param1 );
  //  this.param1 = decodedString;
  //  alert(this.param1)
    this.call_record_id = this.route.snapshot.queryParamMap.get('call_rec_id');
    this.fromEdit = this.route.snapshot.queryParamMap.get('from_edit');
    this.IFlink = this.route.snapshot.queryParamMap.get('clink');
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
   }


  ngOnInit(): void {
    this.uadmin_id = localStorage.getItem('userId');
    this.admin_id = localStorage.getItem('admin_id');
     this.show_caller_id = localStorage.getItem('show_caller_id');
     var link="http://43.240.112.152/buzz_sure/backend/web/telephony/phone/customer-tel-number";
     this.getDepartments();
     this.getAuxCode();
     this.getCallerDetails();
     this.getCamps();
  }
getquestionnaire(){
    let access_token: any=localStorage.getItem('access_token');

    let api_req:any = '{"operation":"questionaire", "moduleType":"questionaire", "api_type": "web", "access_token":"'+access_token+'", "element_data":{"action":"get_user_queue","user_id":"'+this.uadmin_id+'"}}';

    this.serverService.sendServer(api_req).subscribe((response:any) => {
      if(response.status==true){
        // $('.settingSidebar').addClass('showSettingPanel');
        this.questions= response.result.data;
      } else {
      }
    },
    (error)=>{
        console.log(error);
    });
  }

  getDepartments(){
    let access_token: any=localStorage.getItem('access_token');

    let api_req:any = '{"operation":"ticket", "moduleType":"ticket", "api_type": "web", "access_token":"'+access_token+'", "element_data":{"action":"get_dept_settings","user_id":"'+this.uadmin_id+'"}}';

    this.serverService.sendServer(api_req).subscribe((response:any) => {
      if(response.result.status==true){
        this.departments = response.result.data;
      } else {
      }
    },
    (error)=>{
        console.log(error);
    });
  }


  getAuxCode( ){
    let access_token: any=localStorage.getItem('access_token');

    let api_req:any = '{"operation":"getAuxcode", "moduleType":"contact", "api_type": "web", "access_token":"'+access_token+'", "element_data":{"action":"get_aux_code","admin_id":"'+this.uadmin_id+'"}}';

    this.serverService.sendServer(api_req).subscribe((response:any) => {
      if(response.result.status==true){
        this.auxcodes = response.result.data;

      } else {
      }
    },
    (error)=>{
        console.log(error);
    });
  }

  getCallerDetails(){
    let access_token: any=localStorage.getItem('access_token');

    let api_req:any = '{"operation":"predective_dialer_contact", "moduleType":"predective_dialer_contact", "api_type": "web", "access_token":"'+access_token+'", "element_data":{"action":"camp_call","admin_id":"'+this.uadmin_id+'","phone_no":"'+this.param1+'"}}';

    this.serverService.sendServer(api_req).subscribe((response:any) => {
      if(response.result.status==true){
        //alert(response.result.data)
        this.address =  response.result.data.address;
        this.campaign_id = response.result.data.campaign_id;
        this.city = response.result.data.city;
      //  alert( this.campaign_id);
      //  this.country = response.result.data.country
        this.created_at = response.result.data.created_at;
        this.customer_name = response.result.data.customer_name;
        this.delete_status = response.result.data.delete_status;
        this.dnd = response.result.data.dnd;
        this.lead_id = response.result.data.lead_id;
        this.notes = response.result.data.notes;
        this.queue_status = response.result.data.queue_status;
        this.source_data = response.result.data.source_data;
        this.state = response.result.data.state;
        this.phone_number = response.result.data.phone_number;
        this.updated_at = response.result.data.updated_at;
        this.zipcode = response.result.data.zipcode;
        this.last_name=response.result.data.last_name;
        this.display_name=response.result.data.display_name;
        this.street_address=response.result.data.street_address;
        this.postal_code=response.result.data.postal_code;
        this.feedback=response.result.data.feedback;
        this.erp_link=response.result.data.erp_link;
        this.past_notes=response.result.data.past_notes;
        this.event_history=response.result.data.event_history;
        this.contact_id=response.result.data.id;
        this.duration=response.result.data.calsl_noted;
        console.log( this.duration)

        $('#camp_names').val(this.campaign_id ).prop('selected', true);

      }
    },
    (error)=>{
        console.log(error);
    });
  }




  getCamps(){
    let access_token: any=localStorage.getItem('access_token');
    let user_is: any=localStorage.getItem('userId');

    let api_req:any = '{"operation":"predective_dialer_contact", "moduleType":"predective_dialer_contact", "api_type": "web", "access_token":"'+access_token+'", "element_data":{"action":"get_campaign","admin_id":"'+user_is+'"}}';

    this.serverService.sendServer(api_req).subscribe((response:any) => {
      if(response.result.status==true){
        this.campaigns = response.result.data;
        $('#wrapup').val(this.dnd).prop('selected', true);
      } else {
      }
    },
    (error)=>{
        console.log(error);
    });
  }


 changeStatus(){
    let access_token: any=localStorage.getItem('access_token');
    let user_is: any=localStorage.getItem('userId');
    var status=$('#wrapup').val();
 //  alert(status);
    var stat = $('#wrapup').val();
   var note = $('#note').val();
   var fname=$('#pre_customer_name').val();
   var lname=$('#pre_last_name').val();
   var erplink=this.erp_link;
   var dnd_status = '0';  if($('#dnd_status').prop('checked')){ dnd_status = '1'; }
   var id:any;
    //var id=
    if(status=='0' ||status=='null'||status==null){
      iziToast.error({
        message: "Please Enter the Wrapup Code",
        position: 'topRight'
    });

    return false;
    }
    var test = note.replace(/(\r\n|\n|\r)/gm," ")
console.log(test);
   
    let api_req:any = '{"operation":"predective_dialer_contact", "moduleType":"predective_dialer_contact", "api_type": "web", "access_token":"'+access_token+'", "element_data":{"action":"update_camp_call","admin_id":"'+user_is+'","phone_no":"'+ this.param1+'","stat":"'+stat+'","camp_id":"'+this.campaign_id+'","dnd_status":"'+dnd_status+'","note":"'+test+'","fname":"'+fname+'","lname":"'+lname+'","erplink":"'+erplink+'"}}';
    console.log(api_req)
  //  return false;
    this.serverService.sendServer(api_req).subscribe((response:any) => {
      if(response.result.status==true){
        iziToast.success({
          message: "Data Updated",
          position: 'topRight'
        });
        if(stat=='fax&vm'){
        this.router.navigate(['/predictive-wrapups'], { queryParams: { page: 'cHJlZGljX0ZBWF9WTQ' } });
        }
        if(stat=='interested'){
          this.router.navigate(['/predictive-wrapups'], { queryParams: { page: 'cHJlZGljX0ludGVyZXN0ZWQ=' } });
          }
          if(stat=='unanswered'){
            this.router.navigate(['/predictive-wrapups'], { queryParams: { page: 'cHJlZGljX05vX0Fuc3dlcg==' } });
            }
            if(stat=='call-back'){
              this.router.navigate(['/predictive-wrapups'], { queryParams: { page: 'cHJlZGljX0NhbGxfQmFjaw==' } });
              }
              if(stat=='dnd'){
                this.router.navigate(['/predictive-wrapups'], { queryParams: { page: 'cHJlZGljX0RORA==' } });
                }
                if(stat=='answered'){
                  this.router.navigate(['/predictive-wrapups'], { queryParams: { page: 'cHJlZGljX2Fuc3dlcmVk' } });
                  }

      } else {
      }
    },
    (error)=>{
        console.log(error);
    });
  }
  updateData(){



    let api_req:any = new Object();
    let agents_req:any = new Object();
    api_req.operation="predective_dialer_contact";
    api_req.moduleType="predective_dialer_contact";
    api_req.api_type="web";
    api_req.access_token=localStorage.getItem('access_token');


    agents_req.customer_name =$('#pre_customer_name').val();
    agents_req.address_new = $('#pre_address').val();
    agents_req.state =$('#pre_state').val();
    agents_req.city = $('#pre_city').val();
    agents_req.zipcode = $('#pre_zipcode').val();
    agents_req.country = $('#pre_country').val();
    agents_req.phone_number =this.phone_number;
    agents_req.source_data = $('#pre_source_data').val();
    agents_req.notes =$('#pre_notes').val();
   // alert( agents_req.notes);
    agents_req.campaign_id= $('#pre_camp_names').val();
    agents_req.last_name= $('#pre_last_name').val();
    agents_req.display_name= $('#pre_display_name').val();
    agents_req.street_address= $('pre_street_address').val();
    agents_req.postal_code= $('pre_postal_code').val();
    agents_req.feedback= $('pre_feedback').val();
    agents_req.contact_id= this.contact_id;


    agents_req.action='update_contact';

    agents_req.user_id = localStorage.getItem('admin_id');
    agents_req.admin_id = localStorage.getItem('admin_id');
    api_req.element_data=agents_req;

    this.serverService.sendServer(api_req).subscribe((response: any) => {
    if (response.result.data == 1) {
            iziToast.success({
                message: "Contact updated successfully",
                position: 'topRight'
            });
        }
    else{
            iziToast.warning({
                message: "Contact not updated. Please try again",
                position: 'topRight'
            });

    }

  },
  (error) => {
     iziToast.error({
        message: "Sorry, some server issue occur. Please contact admin",
        position: 'topRight'
    });
    console.log(error);
  });
  }
  test(){
    this.router.navigate(['/campaign-contact']);
  }
}


