import { Component, OnInit } from '@angular/core';
import * as RecordRTC from 'recordrtc';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';
declare var $:any;
interface RecordedVideoOutput {
  blob: Blob;
  url: string;
  title: string;
}
@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.css']
})
export class TestComponent implements OnInit {
  private stream: any;
  private recorder: any;
  private interval: any;
  private startTime: any;
  private _stream = new Subject<MediaStream>();
  private _recorded = new Subject<RecordedVideoOutput>();
  private _recordedUrl = new Subject<string>();
  private _recordingTime = new Subject<string>();
  private _recordingFailed = new Subject<string>();
  getRecordedUrl(): Observable<string> {
    return this._recordedUrl.asObservable();
  }

  getRecordedBlob(): Observable<RecordedVideoOutput> {
    return this._recorded.asObservable();
  }

  getRecordedTime(): Observable<string> {
    return this._recordingTime.asObservable();
  }

  recordingFailed(): Observable<string> {
    return this._recordingFailed.asObservable();
  }

  getStream(): Observable<MediaStream> {
    return this._stream.asObservable();
  }

  startRecording() {
    var browser = <any>navigator;
    if (this.recorder) {
      // It means recording is already started or it is already recording something
      return;
    }

    this._recordingTime.next('00:00');

    return new Promise((resolve, reject) => {
      browser.mediaDevices
        .getDisplayMedia({
          video: {
            displaySurface: 'browser', // monitor, window, application, browser
            logicalSurface: true,
            cursor: 'always', // never, always, motion
          },
        })
        .then((screenStream: any) => {
          navigator.mediaDevices.getUserMedia({ audio: true }).then((mic) => {
            screenStream.addTrack(mic.getTracks()[0]);
            this.stream = screenStream;
            this.record();
            resolve(this.stream);
          });
        })
        .catch((error: any) => {
          this._recordingFailed.next();
          reject;
        });
    });
  }

  abortRecording() {
    this.stopMedia();
  }

  private record() {
    this.recorder = new RecordRTC(this.stream, {
      type: 'video',
      mimeType: 'video/webm',
      bitsPerSecond: 44000,
    });
    this.recorder.startRecording();
    this.startTime = moment();
    this.interval = setInterval(() => {
      const currentTime = moment();
      const diffTime = moment.duration(currentTime.diff(this.startTime));
      const time =
        this.toString(diffTime.minutes()) +
        ':' +
        this.toString(diffTime.seconds());
      this._recordingTime.next(time);
      this._stream.next(this.stream);
    }, 500);
  }

  private toString(value: any) {
    let val = value;
    if (!value) {
      val = '00';
    }
    if (value < 10) {
      val = '0' + value;
    }
    return val;
  }

  stopRecording() {
    if (this.recorder) {
      this.recorder.stopRecording(this.processVideo.bind(this));
      this.processVideo.bind(this.recorder)
      this.processVideo(this.recorder);
      this.stopMedia();
      
    }
    
  }

  private processVideo(audioVideoWebMURL: any) {
    console.log(audioVideoWebMURL);
    $("#video").attr("src", audioVideoWebMURL)​
    // var file = new File([audioVideoWebMURL],'test');
    const recordedName = encodeURIComponent(
      'video_' + new Date().getTime() + '.webm'
    );
    var file = this.blobToFile(audioVideoWebMURL,recordedName);
    console.log(file);
    // const recordedBlob = this.recorder.getBlob();
    // var blob = this.recorder.getBlob();
    // console.log(recordedBlob);
    // this.recorder.getDataURL(function (dataURL: any) {});
    // const recordedName = encodeURIComponent(
    //   'video_' + new Date().getTime() + '.webm'
    // );
    // var test =this._recorded.next({
    //   blob: recordedBlob,
    //   url: audioVideoWebMURL, 
    //   title: recordedName,
    // });
    console.log('video processing');
    // console.log(test);
    console.log(this._recorded.next());
    let recordurlvalue = this.getRecordedUrl();
    console.log(recordurlvalue);
    this.stopMedia();

    
    // this.recorder.save(recordedName);
  }
  private stopMedia() {
    if (this.recorder) {
      this.recorder = null;
      clearInterval(this.interval);
      this.startTime = null;
      if (this.stream) {
        this.stream.getAudioTracks().forEach((track: any) => track.stop());
        this.stream.getVideoTracks().forEach((track: any) => track.stop());
        this.stream.stop();
        this.stream = null;
      }
    }
  }
  constructor() { }

  ngOnInit(): void {

  }


  public blobToFile = (theBlob: Blob, fileName:string): File => {       
    return new File([theBlob], fileName, { lastModified: new Date().getTime(), type: theBlob.type })
}
}
