// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // firebase: {
  //   apiKey: "AIzaSyBUUHyIrXA3nv5cGHOOrj-ugdo6HiblQKM",
  //   authDomain: "omnichannel-3d3e1.firebaseapp.com",
  //   databaseURL: "https://omnichannel-3d3e1.firebaseio.com",
  //   projectId: "omnichannel-3d3e1",
  //   storageBucket: "omnichannel-3d3e1.appspot.com",
  //   messagingSenderId: "647359758084",
  //   appId: "1:647359758084:web:056430784643dc4670da51",
  //   measurementId: "G-8VWBZL1G0C"
  // }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
