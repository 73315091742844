import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-upgrades',
  templateUrl: './upgrades.component.html',
  styleUrls: ['./upgrades.component.css']
})
export class UpgradesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
